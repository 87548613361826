import React from 'react';

import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';

import AndroidIcon from '@material-ui/icons/Android';

const styles = theme => ({
  main: {
    textAlign: 'center',
    paddingTop: 10,
    paddingLeft: 10,
  },
});

class MainPage extends React.Component {
  render() {
    const { classes } = this.props;

    return (
      <div className={classes.main}>
        <Typography variant="h2" gutterBottom>
          About
        </Typography>
        <Typography variant="subtitle1" gutterBottom>
          gPythapi by René Fa (GPLv3) <a href='https://github.com/denialofsandwich'>https://github.com/denialofsandwich</a><br/>
        </Typography>
        <Typography variant="h4" gutterBottom>
          Used Libaries and Frameworks
        </Typography>
        <Typography variant="subtitle1" gutterBottom>
          React (MIT): <a href='https://reactjs.org/'>https://reactjs.org/</a><br/>
          React Material-UI (MIT): <a href='https://material-ui.com/'>https://material-ui.com/</a><br/>
          React Router (MIT): <a href='https://reacttraining.com/react-router/'>https://reacttraining.com/react-router/</a><br/>
          Axios (MIT): <a href='https://github.com/axios/axios'>https://github.com/axios/axios</a><br/>
          React-JSON-View (MIT): <a href='https://github.com/mac-s-g/react-json-view'>https://github.com/mac-s-g/react-json-view</a><br/>
          Login Background SVG (CC_BY_4.0): <a href='https://www.svgbackgrounds.com/'>https://www.svgbackgrounds.com/</a><br/>
        </Typography>
      </div>
    );
  }
}

const export_obj = {
  name: 'about',
  f_name: 'About',
  icon: AndroidIcon,
  component: withStyles(styles)(MainPage),
};

export default export_obj;
