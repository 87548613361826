import React from 'react';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';

import AndroidIcon from '@material-ui/icons/Android';

const styles = theme => ({
  main: {
    paddingTop: 10,
    paddingLeft: 10,
  },
  boringButton: {
    marginRight: 10,
  },
});

class MainPage extends React.Component {
  state = {
    open: false,
  };
  
  handleClose = () => {
    this.setState({
      open: false,
    });
  };

  handleClick = () => {
    this.setState({
      open: true,
    });
  };

  render() {
    const { classes } = this.props;
    const { open } = this.state;

    return (
      <div className={classes.main}>
        <Dialog open={open} onClose={this.handleClose}>
          <DialogTitle>Read a cool message</DialogTitle>
          <DialogContent>
            <DialogContentText>1-2-3-4-5</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button color="primary" onClick={this.handleClose}>
              OK
            </Button>
          </DialogActions>
        </Dialog>
        <Typography variant="h4" gutterBottom>
          Hunt Showdown
        </Typography>
        <Typography variant="subtitle1" gutterBottom>
          example project
        </Typography>
        <Button className={classes.boringButton} variant="contained" color="primary" onClick={this.handleClick}>
          Open Dialog
        </Button>
      </div>
    );
  }
}

const export_obj = {
  name: 'testpage1',
  f_name: 'Testseite 1',
  icon: AndroidIcon,
  component: withStyles(styles)(MainPage),
};

export default export_obj;
