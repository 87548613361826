import Login from './pages/login';
import NotFound from './pages/not_found';
import About from './pages/about';
import APIExplorer from './pages/api_explorer';
import Test1 from './pages/test1';
import Test2 from './pages/test2';

export default {
  not_found: NotFound,
  login: Login,
  about: About,
  [APIExplorer.name]: APIExplorer,
  [Test1.name]: Test1,
  [Test2.name]: Test2,
};
