import React from 'react';
import { Route, Switch, Link } from "react-router-dom";
import * as _ from 'underscore';
import classNames from 'classnames';
import axios from 'axios';
import ReactJson from 'react-json-view'

import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';

import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelActions from '@material-ui/core/ExpansionPanelActions';
import Divider from '@material-ui/core/Divider';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Hidden from '@material-ui/core/Hidden';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

import Fab from '@material-ui/core/Fab';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import TextField from '@material-ui/core/TextField';

import CategoryIcon from '@material-ui/icons/Category';
import Icon from '@material-ui/core/Icon';

const styles = theme => ({
  main: {
    paddingTop: 10,
    [theme.breakpoints.up('sm')]: {
      paddingLeft: 5,
    },
  },
});

const stylesPluginCard = theme => ({
  card: {
    minWidth: 275,
    margin: 5,
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    
    [theme.breakpoints.up('sm')]: {
      width: 300,
    },
  },
});

const stylesAction = theme => ({
  main: {
    padding: 5,
    paddingTop: 10,
    [theme.breakpoints.up('sm')]: {
      padding: 10,
    },
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexShrink: 0,
    
    [theme.breakpoints.up('sm')]: {
      flexBasis: '33.33%',
    },
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  section: {
    marginBottom: 15,
  },
  notPermitted: {
    color: '#d32f2f',
  },
});

const stylesExecPanel = theme => ({
  main: {
    display: 'flex',
    flexDirection: 'column',
    padding: 5,
    paddingTop: 10,
    [theme.breakpoints.up('sm')]: {
      padding: 10,
    },
  },
  fab: {
    position: 'fixed',
    bottom: theme.spacing.unit * 2,
    right: theme.spacing.unit * 2,
    
    [theme.breakpoints.up('sm')]: {
      bottom: theme.spacing.unit * 4,
    },
  },
  textField: {
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
    [theme.breakpoints.up('sm')]: {
      marginRight: 10,
    },
  },
});

class ExecutionPanel extends React.Component {
  state = {
    actionData: {},
    postBody: {},
    getArgs: {},
    lastResponse: {},
  };
  
  componentDidMount() {
    const pluginName = this.props.match.params.pluginName;
    const actionName = this.props.match.params.actionName;
    this.props.setLoading(250);
    
    axios.get('/api/info/' +pluginName +'/' +actionName)
      .then(response => {
        this.props.setLoading(0);
        this.setState({actionData: response.data.data});
      })
      .catch(error => {
        this.props.setLoading(0);
        this.props.snackbar_message('Fehler beim Laden der Actionliste.', 'error');
      });
  }
  
  editJSON = (objectName) => (data) => {
    this.setState({[objectName]: data.updated_src});
    return true;
  };
  
  executeCall = () => {
    this.props.setLoading(250);
    const { actionData, postBody, getArgs } = this.state;
    
    var urlQueryParams = Object.keys(getArgs).map((k) => {
      return encodeURIComponent(k) + '=' + encodeURIComponent(getArgs[k])
    }).join('&')
    
    var url = '/api/' +actionData.regex.replace(/^\^/,'').replace(/\$$/,'') +'?' +urlQueryParams;
    var paramCount = (this.state.actionData.regex.match(/\(/g) || []).length;
    
    for(var i = 0; i < paramCount; i++) {
      url = url.replace(/\(.+\)/, this.state['params_' +i]);
    }
    
    console.log(url);
    
    axios({
        headers: { 'content-type': 'application/json' },
        method: actionData.method,
        url: url,
        data: postBody,
      })
      .then(response => {
        this.props.setLoading(0);
        this.setState({lastResponse: response.data});
      })
      .catch(error => {
        this.props.setLoading(0);
        this.props.snackbar_message('Fehler bei Ausführung.', 'error');
        this.setState({lastResponse: error.response.data});
      });
  };
  
  getInputChanges = stateName => e => {
    this.setState({[stateName]: e.target.value})
  }
  
  render() {
    const { classes } = this.props;
    const { actionData } = this.state;
    
    if(_.isEmpty(actionData)) {
      return (<div/>);
    }
    
    var paramCount = (actionData.regex.match(/\(/g) || []).length;
    
    return (
      <div className={classes.main}>
        <Typography variant='h5' gutterBottom>{actionData.f_name}</Typography>
        {paramCount > 0 ? (
          <div>
            <Typography variant='h6' gutterBottom>Params</Typography>
            {'params' in actionData ? (
              <div>
                {actionData.params.map((item, i) => (
                  <TextField
                    key={i}
                    label={item.f_name}
                    className={classes.textField}
                    margin="dense"
                    variant="outlined"
                    onChange={this.getInputChanges('params_' +i)}
                  />
                ))}
              </div>
            ) : (
              <div>
                {actionData.regex.match(/\(/g).map((item, i) => (
                  <TextField
                    key={i}
                    label={"Parameter " +(i+1)}
                    margin="dense"
                    variant="outlined"
                    onChange={this.getInputChanges('params_' +i)}
                  />
                ))}
              </div>
            )}
          </div>
        ) : (<div/>)}
        
        {actionData.method === 'GET' ? (<div/>) : (
          <div>
            <Typography variant='h6' gutterBottom>Post Body</Typography>
            <ReactJson
              src={this.state.postBody}
              onAdd={this.editJSON('postBody')}
              onDelete={this.editJSON('postBody')}
              onEdit={this.editJSON('postBody')}
              displayDataTypes={false}
            />
          </div>
        )}
        
        <Typography variant='h6' gutterBottom>Get Arguments</Typography>
        <ReactJson
          src={this.state.getArgs}
          onAdd={this.editJSON('getArgs')}
          onDelete={this.editJSON('getArgs')}
          onEdit={this.editJSON('getArgs')}
          displayDataTypes={false}
        />
        
        <Typography variant='h6' gutterBottom>Last Response</Typography>
        <ReactJson
          src={this.state.lastResponse}
          displayDataTypes={false}
        />
        
        <Typography variant='h6' gutterBottom>Raw Info</Typography>
        <ReactJson src={this.state.actionData} collapsed={0}/>
        <Fab color="primary" aria-label="Execute" className={classes.fab} onClick={this.executeCall}>
          <PlayArrowIcon />
        </Fab>
      </div>
    );
  }
}

ExecutionPanel.defaultProps = {
  pluginName: '',
};

ExecutionPanel = withStyles(stylesExecPanel)(ExecutionPanel);

function MethodIcon(props) {
  const typeColor = {
    GET: '#388e3c',
    POST: '#c6930f',
    PUT: '#3949ab',
    DELETE: '#d32f2f',
  };
  
  return (
    <div style={{
      width: 60,
      marginRight: 10,
      
      borderRadius: 3,
    }}>
      <Typography variant='button' style={{
        color: typeColor[props.type],
      }}>
        {props.type}
      </Typography>
    </div>
  )
};

class ActionList extends React.Component {
  state = {
    expandedAction: {open: false},
    expandedPlugin: {open: false},
    pluginRawData: {},
    pluginRows: [],
    actionList: [],
    actionDict: {},
  };
  
  pluginMainAttributes = ['name', 'f_name', 'f_description', 'version', 'f_icon', 'action_count', 'essential'];
  
  tryFillPluginRows = () => {
    if (!(this.props.match.params.pluginName in this.props.info_dict)) {
      return;
    }
    
    const plugin_data = this.props.info_dict[this.props.match.params.pluginName];
    
    this.setState({
      pluginRows: this.pluginMainAttributes.reduce((result, item) => {
        if (item in plugin_data) {
          var item_data = plugin_data[item];
          if (typeof(plugin_data[item]) == 'boolean') {
            item_data = plugin_data[item] ? 'True' : 'False';
          }
          result.push({k: item, v: item_data});
        }
        return result;
      }, []),
      pluginRawData: plugin_data,
    });
}
  
  componentDidMount() {
    this.tryFillPluginRows()
    const pluginName = this.props.match.params.pluginName;
    this.props.setLoading(250);
    
    axios.get('/api/info/' +pluginName +'/list?verbose=true')
      .then(response => {
        this.props.setLoading(0);
        
        var data = response.data.data;
        const methodWeight= {GET: 0, POST: 1, PUT: 2, DELETE: 3}
        data.sort((a, b) => {
          if(a.regex === b.regex) {
            return methodWeight[a.method] > methodWeight[b.method] ? 1 : -1;
          }
          
          return a.regex > b.regex ? 1 : -1;
        });
        
        var data_dict = {};
        data.forEach(item => {
          data_dict[item.name] = item;
        })
        
        this.setState({
          actionList: data,
          actionDict: data_dict,
        });
      })
      .catch(error => {
        this.props.setLoading(0);
        this.props.snackbar_message('Fehler beim Laden der Actionliste.', 'error');
      });
  }
  
  componentDidUpdate(prevProps, prevState, snapshot) {
    if(prevProps.info_dict !== this.props.info_dict) {
      this.tryFillPluginRows()
    }
  }
  
  handleChange = (section, panel) => (event, isExpanded) => {
    var secState = this.state[section];
    secState.open = isExpanded ? panel : false;
    secState[panel] = true;
    
    this.setState({[section]: secState});
  };
  
  render() {
    const { classes, match } = this.props;
    const { expandedAction, expandedPlugin, actionList, pluginRows} = this.state;
    const pluginName = match.params.pluginName
    
    return (
      <div className={classes.main}>

        <div className={classes.section}>
          <Typography variant='h5' gutterBottom>Plugin Info</Typography>
          <Paper style={{overflowX: 'auto'}}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Attribute</TableCell>
                  <TableCell>Value</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {pluginRows.map((item, i) => (
                  <TableRow key={i}>
                    <TableCell component="th" scope="row">
                      <Typography>{item.k}</Typography>
                    </TableCell>
                    <TableCell><Typography>{item.v}</Typography></TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Paper>
        
          <div>
            <ExpansionPanel expanded={expandedPlugin.open === true} onChange={this.handleChange('expandedPlugin', true)}>
              <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                <Typography className={classes.secondaryHeading}>Show raw Data</Typography>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                <ReactJson src={this.state.pluginRawData}/>
              </ExpansionPanelDetails>
            </ExpansionPanel>
          </div>
        </div>
        
        <Typography variant='h5' gutterBottom>Action List ({actionList.length})</Typography>
        
        <div className={classes.section}>
          {actionList.map((item, i) => (
            <ExpansionPanel key={i} expanded={expandedAction.open === i} onChange={this.handleChange('expandedAction', i)}>
              <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                <MethodIcon type={item.method}/>
                <Typography className={classNames(classes.heading, {
                    [classes.notPermitted]: !item.permitted,
                  })}
                >
                  {_.isUndefined(item.path) ? item.regex : ('/' +pluginName +'/' +item.path)}
                </Typography>
                <Hidden xsDown implementation="css">
                  <Typography className={classes.secondaryHeading}>{item.f_name}</Typography>
                </Hidden>
              </ExpansionPanelSummary>
              {!_.isUndefined(expandedAction[i]) ? (
                <div>
                  <ExpansionPanelDetails style={{flexDirection: 'column'}}>
                    <Typography variant='h6'>{item.f_name}</Typography>
                    <Typography variant="body1" gutterBottom>
                      {item.f_description}
                    </Typography>
                    <ReactJson src={item} collapsed={0}/>
                  </ExpansionPanelDetails>
                  <Divider/>
                  <ExpansionPanelActions>
                    <Button size="small">Open Documentation</Button>
                    <Button size="small" color="primary" component={Link} to={'/' +export_obj.name +'/exec/' +pluginName +'/' +item.name.split('.')[1]}>
                      Open Execution Panel
                    </Button>
                  </ExpansionPanelActions>
                </div>
              ) : (<div/>)}
            </ExpansionPanel>
          ))}
        </div>
      </div>
    );
  }
}

ActionList.defaultProps = {
  pluginName: '',
};

ActionList = withStyles(stylesAction)(ActionList);

class PluginCard extends React.Component {
  render() {
    const item = this.props.data;
    
    return (
      <Card className={this.props.classes.card}>
        <div style={{display: 'flex', paddingTop: 16, paddingLeft: 16, paddingRight: 16, marginBottom: 'auto'}}>
          <div style={{marginRight: 'auto'}}>
            <Typography variant="h5" component="h2">
              {item.f_name}
            </Typography>
            <Typography variant="body1">
              {item.f_description}
            </Typography>
          </div>
          <div>
            <Icon style={{fontSize: 45,}}>{'f_icon' in item ? item.f_icon : 'extension'}</Icon>
          </div>
        </div>
        <div style={{display: 'flex', paddingRight: 16}}>
          <CardActions style={{marginRight: 'auto'}}>
            <Button onClick={this.props.onOpen(item.name)}>Open</Button>
          </CardActions>
          <div style={{marginTop: 'auto', marginBottom: 12}}>
            <Typography variant="body1">
              {item.action_count}
            </Typography>
          </div>
        </div>
      </Card>
    )
  };
};
PluginCard = withStyles(stylesPluginCard)(PluginCard);

class PluginList extends React.Component {
  state = {
    info_list: [],
    info_dict: {},
  };
  
  componentDidMount() {
    this.props.setLoading(250);
    axios.get(`/api/info/list?verbose=true`)
      .then(response => {
        this.props.setLoading(0);
        
        var data = response.data.data;
        data.sort((a, b) => {
          return a.f_name > b.f_name ? 1 : -1;
        });
        
        var data_dict = {};
        data.forEach(item => {
          data_dict[item.name] = item;
        })
        
        this.setState({
          info_list: data,
          info_dict: data_dict,
        });
      })
      .catch(error => {
        this.props.setLoading(0);
        this.props.snackbar_message('Fehler beim Laden der Pluginliste.', 'error');
      });
  }
  
  redirectTo = pluginName => () => {
    this.props.history.push('/' +export_obj.name +'/explore/' +pluginName);
  };
  
  render() {
    const { classes, history, location, snackbar_message, userData, setLoading } = this.props;
    
    return (
      <Switch>
        <Route path='/:PageName/exec/:pluginName/:actionName' render={props => (
          <ExecutionPanel  history={history} location={location} snackbar_message={snackbar_message} userData={userData} setLoading={setLoading} match={props.match}/>
        )}/>
        <Route path="/:pageName/explore/:pluginName" render={props => (
            <ActionList history={history} location={location} snackbar_message={snackbar_message} userData={userData} setLoading={setLoading} info_dict={this.state.info_dict} match={props.match}></ActionList>
          )
        }/>
        <Route render={props => (
          <div className={classes.main}>
            <Grid container>
              {this.state.info_list.map((item, i) => (
                <PluginCard key={i} data={item} onOpen={this.redirectTo}/>
              ))}
            </Grid>
          </div>
        )}/>
      </Switch>
    );
  }
}

const export_obj = {
  name: 'api_explorer',
  f_name: 'API Explorer',
  icon: CategoryIcon,
  component: withStyles(styles)(PluginList),
};

export default export_obj;
