import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import axios from 'axios';
import { Link } from "react-router-dom";

import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Avatar from '@material-ui/core/Avatar';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Paper from '@material-ui/core/Paper';
import LinearProgress from '@material-ui/core/LinearProgress';

import AndroidIcon from '@material-ui/icons/Android';

const loginStyle = theme => ({
  bgImage: {
    backgroundImage: 'url("/large_triangles.svg")',
    backgroundRepeat: 'repeat',
    
    [theme.breakpoints.down('xs')]: {
      backgroundSize: '250px',
    },
    
    position: 'fixed',
    zIndex: -500,
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
  },
  main: {
    width: 'auto',
    display: 'block', // Fix IE 11 issue.
    marginLeft: theme.spacing.unit * 3,
    marginRight: theme.spacing.unit * 3,
    [theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
      width: 400,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  paper: {
    marginTop: theme.spacing.unit * 8,
    backgroundColor: 'white',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme.spacing.unit * 3}px`,
  },
  avatar: {
    margin: theme.spacing.unit,
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing.unit,
  },
  submit: {
    marginTop: theme.spacing.unit * 3,
  },
  buttonProgress: {
    marginTop: -4,
  },
  hide: {
    display: 'none',
  },
  bgLink: {
    textDecoration: 'none',
    color: 'white',
  },
  linkSection: {
    position: 'absolute',
    bottom: 5,
    right: 10,
  },
});

class LoginPage extends React.Component {
  state = {
    loading: false,
    ban_time: 0,
    username: '',
    password: '',
    rememberMe: false,
  };
  
  countdownBanTime = () => {
    var new_banTime = this.state.ban_time -1;
    
    if (new_banTime > 0) {
      setTimeout(this.countdownBanTime, 1000);
    }
    this.setState({ban_time: this.state.ban_time-1});
  }
  
  handleLogin = (e) => {
    e.preventDefault();
    
    if (!this.state.loading) {
      this.setState({loading: true})
    }
    
    axios.post(`/api/auth/session`, JSON.stringify({
      username: this.state.username,
      password: this.state.password,
      persistent: this.state.rememberMe,
    }))
      .then(response => {
        this.props.history.replace('/');
        this.props.snackbar_message('Erfolgreich eingeloggt.', 'success');
      })
      .catch(error => {
        const r_data = error.response.data
        this.setState({loading: false});
        
        if ('remaining_time' in r_data || 'ban_time' in r_data) {
          this.setState({ban_time: r_data.remaining_time || r_data.ban_time})
          setTimeout(this.countdownBanTime, 1000);
        }
        
        this.props.snackbar_message(r_data.message, 'error');
      });
  }
  
  getInputChanges_Username = (e) => {
    this.setState({username: e.target.value})
  }
  
  getInputChanges_Password = (e) => {
    this.setState({password: e.target.value})
  }
  
  getInputChanges_RememberMe = (e) => {
    this.setState({rememberMe: e.target.checked})
  }
  
  render() {
    const { classes } = this.props;
    const { loading, ban_time } = this.state;

    return (
      <main className={classes.main}>
        <div className={classes.bgImage}/>
        <Paper className={classes.paper}>
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            gPythapi Login
          </Typography>
          <form className={classes.form} onSubmit={this.handleLogin}>
            <FormControl margin="normal" required fullWidth>
              <InputLabel htmlFor="username">Username</InputLabel>
              <Input id="username" name="username" autoComplete="username" onChange={this.getInputChanges_Username}
              />
            </FormControl>
            <FormControl margin="normal" required fullWidth>
              <InputLabel htmlFor="password">Password</InputLabel>
              <Input name="password" type="password" id="password" autoComplete="current-password" onChange={this.getInputChanges_Password}
              />
            </FormControl>
            <FormControlLabel
              control={<Checkbox onChange={this.getInputChanges_RememberMe} value="remember" color="primary" />}
              label="Remember me"
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              disabled={loading || ban_time !== 0}
              className={classes.submit}
            >
              {ban_time ? ban_time : "Sign in"}
            </Button>
            <LinearProgress className={classNames(classes.buttonProgress, {
                [classes.hide]: !this.state.loading,
              })}
            />
          </form>
        </Paper>
        <div className={classes.linkSection}><Typography component='subtitle1'><Link className={classes.bgLink} to="/about">About</Link></Typography></div>
      </main>
    );
  }
}

LoginPage.propTypes = {
  classes: PropTypes.object.isRequired,
};

const export_obj = {
  name: 'login',
  f_name: 'Login page',
  icon: AndroidIcon,
  component: withStyles(loginStyle)(LoginPage),
};

export default export_obj;
