import React from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect } from "react-router-dom";
import axios from 'axios';

import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';

import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import LinearProgress from '@material-ui/core/LinearProgress';

import InboxIcon from '@material-ui/icons/MoveToInbox';
import MailIcon from '@material-ui/icons/Mail';
import PowerSettingsNew from '@material-ui/icons/PowerSettingsNew';

import withRoot from './withRoot';
import FullAppBar from './components/app_bar';
import FancySnackbar from './components/fancy_snackbar';
import PageList from './pages';

const styles = theme => ({
  selectedPage: {
    backgroundColor: '#D3D3D3',
  },
});

class MainPage extends React.Component {
  state = {
    pageTitle: 'N/A',
    sigDrawerClose: 0,
    loading: 0,
    pages: ['not_found'],
    startpage: 'not_found',
  };
  
  handleLogout = () => {
    this.setLoading(250);
    axios.delete(`/api/auth/session`)
      .then(response => {
        this.setLoading(0);
        this.props.snackbar_message('Erfolgreich ausgeloggt.', 'success');
        this.props.history.replace('/login')
      })
      .catch(error => {
        this.setLoading(0);
        console.error(error.stack);
        const r_data = error.response.data
        this.props.snackbar_message(r_data.message, 'error');
        this.props.history.replace('/login')
      });
  }
  
  setLoading = (t) => {
    this.setState({loading: t})
  }
  
  drawer_items = [
    {
      type: 'list',
      data: this.props.pages.map((item, i) => {
        return {
          f_name: PageList[item].f_name,
          name: PageList[item].name,
          icon: PageList[item].icon,
          onClick: () => {
            this.setState({sigDrawerClose: this.state.sigDrawerClose?0:1})
            this.props.history.push('/' +PageList[item].name);
          },
        }
      }),
    },
    {
      type: 'divider'
    },
    {
      type: 'list',
      data: [
        {
          f_name: 'Enable Loading',
          icon: InboxIcon,
          onClick: () => {this.setLoading(250)},
        },
        {
          f_name: 'Disable Loading',
          icon: MailIcon,
          onClick: () => {this.setLoading(0)},
        },
        {
          f_name: 'Unload SW',
          icon: InboxIcon,
          onClick: () => {
            this.props.serviceWorker.unregister();
            console.log(window.location.reload());
          }
        },
        {
          f_name: 'Petersilie',
          icon: MailIcon,
        },
      ],
    },
    {
      type: 'divider'
    },
    {
      type: 'list',
      data: [
        {
          f_name: 'Logout',
          icon: PowerSettingsNew,
          onClick: this.handleLogout,
        },
      ],
    },
  ];
  
  render() {
    if (!(this.props.match.params.pageName in PageList)) {
      return (<Redirect to={'/' +PageList.not_found.name}/>);
    }
    
    const CurrentPage = PageList[this.props.match.params.pageName].component;
    const { classes, history, location, match, snackbar_message, userData } = this.props;
    
    const drawer_content = (
        <div display='flex'>
          {this.drawer_items.map((item, index) => {
            
            if (item.type === 'divider') {
              return (<Divider key={index}/>);
            }
            else {
              return (
                <List key={index}>
                  {item.data.map((subitem, subindex) => {
                    const Icon = subitem.icon;
                    return (
                      <ListItem button
                        key={subindex}
                        onClick={subitem.onClick}
                        className={classNames({
                          [classes.selectedPage]: this.props.match.params.pageName === subitem.name,
                        })}
                      >
                        <ListItemIcon><Icon/></ListItemIcon>
                        <ListItemText primary={subitem.f_name} />
                      </ListItem>
                    );
                  }
                  )}
                </List>
              );
            }
          }
          )}
        </div>
    );
    
    return (
      <FullAppBar appName='gPythapi' title={PageList[this.props.match.params.pageName].f_name} drawerContent={drawer_content} sigDrawerClose={this.state.sigDrawerClose} loading={this.state.loading}>
        <CurrentPage history={history} location={location} match={match} snackbar_message={snackbar_message} userData={userData} setLoading={this.setLoading}/>
      </FullAppBar>
    );
  }
}

MainPage = withStyles(styles)(MainPage)

class MainPageRedirector extends React.Component {
  state = {
    loggedIn: false,
  };
  
  componentDidMount() {
    axios.get(`/api/auth/whoami`)
      .then(response => {
        this.setState({userData: response.data.data})
        this.getFrontendData()
      })
      .catch(error => {
        this.props.history.replace('/login');
      });
  }

  getFrontendData = () => {
    axios.get(`/api/data/user/` +this.state.userData.username +'/gpythapi')
      .then(response => {
        const data = response.data.data;
        
        if (data !== null && 'pages' in data && 'startpage' in data) {
          this.setState({
            loggedIn: true,
            pages: data.pages,
            startpage: data.startpage,
          });
          if (this.props.location.pathname === '/') {
            this.props.history.replace('/' +data.startpage);
          }
        } else {
          this.props.snackbar_message('Benutzer nicht für Frontend eingerichtet.', 'error');
          this.props.history.replace('/login');
        }
      })
      .catch(error => {
        this.props.snackbar_message('Fehler beim Abrufen der Benutzerdaten.', 'error');
        this.props.history.replace('/login');
      });
  }
  
  render() {
    const { loggedIn, pages, startpage, userData } = this.state;
    
    return loggedIn ? (
      <Route path="/:pageName" render={props => (
        <MainPage {...props}  serviceWorker={this.props.serviceWorker} snackbar_message={this.props.snackbar_message} pages={pages} startpage={startpage} userData={userData}/>
      )}/>
    ) : (
      <div>
        <LinearProgress/>
      </div>);
  }
}

class Index extends React.Component {
  state = {
    snackbar_changed: false,
    snackbar_message: 'N/A',
    snackbar_level: 'info',
  };
  
  handleSnackbar = (message, level) => {
    this.setState({
      snackbar_changed: this.state.snackbar_changed ? 0 : 1,
      snackbar_message: message,
      snackbar_level: level,
    })
  }
  
  render() {
    return (
      <Router>
        <div>
          <Switch>
            <Route path="/about" render={props => (
              <PageList.about.component {...props}/>
            )}/>
            <Route path="/login" render={props => (
              <PageList.login.component {...props} snackbar_message={this.handleSnackbar}/>
            )}/>
            <Route path="/" render={props => (
              <MainPageRedirector {...props} serviceWorker={this.props.serviceWorker} snackbar_message={this.handleSnackbar}/>
            )}/>
          </Switch>
          <FancySnackbar changed={this.state.snackbar_changed} level={this.state.snackbar_level} message={this.state.snackbar_message}/>
        </div>
      </Router>
    );
  }
}

export default withRoot(Index);
