import React from 'react';

import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';

import AndroidIcon from '@material-ui/icons/Android';

const styles = theme => ({
  main: {
    paddingTop: 10,
    paddingLeft: 10,
  },
});

class MainPage extends React.Component {
  render() {
    const { classes } = this.props;

    return (
      <div className={classes.main}>
        <Typography variant="h4" gutterBottom>
          Fehler
        </Typography>
        <Typography variant="subtitle1" gutterBottom>
          Seite nicht gefunden.
        </Typography>
      </div>
    );
  }
}

const export_obj = {
  name: 'not_found',
  f_name: 'Seite nicht gefunden',
  icon: AndroidIcon,
  component: withStyles(styles)(MainPage),
};

export default export_obj;
