import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import Hidden from '@material-ui/core/Hidden';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import Drawer from '@material-ui/core/Drawer';
import Divider from '@material-ui/core/Divider';
import LinearProgress from '@material-ui/core/LinearProgress';

const drawerWidth = 240;

const styles = theme => ({
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20,
  },

  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing.unit * 7 + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing.unit * 9 + 1,
    },
  },
  appBar: {
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    
    [theme.breakpoints.up('sm')]: {
      zIndex: theme.zIndex.drawer + 1,
      marginLeft: 0,
    },
  },
  appBarShift: {
    [theme.breakpoints.up('sm')]: {
      marginLeft: drawerWidth,
      width: `calc(100% - ${drawerWidth}px)`,
    },
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    paddingRight: '8px',
    paddingLeft: '15px',
    width: drawerWidth,
    ...theme.mixins.toolbar,
  },
  spacer: {
    width: '100%',
  },
  drawerPaper: {
    width: drawerWidth,
  },
  content: {
    flexGrow: 1,
    
    transition: theme.transitions.create(['padding'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    [theme.breakpoints.up('sm')]: {
      paddingLeft: theme.spacing.unit * 9 + 1,
    },
  },
  contentShift: {
    [theme.breakpoints.up('sm')]: {
      paddingLeft: drawerWidth,
    },
    transition: theme.transitions.create(['padding'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  hide: {
    display: 'none',
  },
});

class FullAppBar extends React.Component {
  state = {
    open: false,
    expand: false,
    loading: false,
    timerId: 0,
  };
  
  handleOpen = () => {
    this.setState({open: true})
  }
  
  handleClose = () => {
    this.setState({open: false})
  }
  
  handleShrink = () => {
    this.setState({expand: false})
  }
  
  handleExpand = () => {
    this.setState({expand: true})
  }
  
  handleLoading = () => {
    this.setState({loading: true})
  }
  
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.sigDrawerClose !== this.props.sigDrawerClose) {
      this.setState({open: false});
    }
    
    if (prevProps.loading !== this.props.loading) {
      if (this.props.loading === 0) {
        clearTimeout(this.state.timerId);
        this.setState({loading: false});
        
      } else {
        const timer_id = setTimeout(this.handleLoading, this.props.loading);
        this.setState({timerId: timer_id});
      }
    }
  }
  
  render() {
    const { classes } = this.props;
    
    return (
      <div>
        <AppBar position="fixed"
          className={classNames(classes.appBar, {
            [classes.appBarShift]: this.state.expand,
          })}
        >
          <Toolbar>
            <Hidden smUp implementation="css">
              <IconButton className={classes.menuButton} color="inherit" aria-label="Menu" onClick={this.handleOpen}>
                <MenuIcon />
              </IconButton>
            </Hidden>
            <Hidden xsDown implementation="css">
              <IconButton
                className={classNames(classes.menuButton, {
                  [classes.hide]: this.state.expand,
                })}
                color="inherit" aria-label="Menu" onClick={this.handleExpand}
              >
                <MenuIcon />
              </IconButton>
            </Hidden>
            <Typography variant="h6" color="inherit" className={classes.grow}>
              {this.props.title}
            </Typography>
          </Toolbar>
          <LinearProgress className={classNames({
              [classes.hide]: !this.state.loading,
            })}
          />
        </AppBar>
        <nav>
          <Hidden smUp implementation="css">
            <SwipeableDrawer
              open={this.state.open}
              onOpen={this.handleOpen}
              onClose={this.handleClose}
            >
                <div className={classes.toolbar}>
                  <Typography variant="h6" color='inherit'>{this.props.appName}</Typography>
                  <div className={classes.spacer}/>
                  <IconButton onClick={this.handleClose}>
                    <ChevronLeftIcon />
                  </IconButton>
                </div>
                <Divider />
                {this.props.drawerContent}
            </SwipeableDrawer>
          </Hidden>
          <Hidden xsDown implementation="css">
            <Drawer
              variant="permanent"
              className={classNames(classes.drawer, {
                [classes.drawerOpen]: this.state.expand,
                [classes.drawerClose]: !this.state.expand,
              })}
              classes={{
                paper: classNames({
                  [classes.drawerOpen]: this.state.expand,
                  [classes.drawerClose]: !this.state.expand,
                }),
              }}
              open={this.state.expand}
            >
              <div className={classes.toolbar}>
                <Typography variant="h6" color='inherit'>{this.props.appName}</Typography>
                <div className={classes.spacer}/>
                <IconButton onClick={this.handleShrink}>
                  <ChevronLeftIcon />
                </IconButton>
              </div>
              <Divider />
              {this.props.drawerContent}
            </Drawer>
          </Hidden>
        </nav>
        <main
          className={classNames(classes.content, {
            [classes.contentShift]: this.state.expand,
          })}
        >
          <div className={classes.toolbar} />
          {this.props.children}
        </main>
      </div>
    );
  }
}

FullAppBar.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(FullAppBar);
